$font--malone: 'Noto Serif JP', 'Malone Serif', Times, 'Times New Roman', serif !default;
$font--lars: 'Noto Sans JP', 'Lars Malone', Helvetica, Arial, sans-serif !default;

// Overrides flag variable value for UI updates
$sub-line-as-title: true;
$is-tax-include: true;
$is-lengthy-engraving-cta: true;
$enable-global-font-for-oab-intro-title: true;
$is-lengthy-mpp-cta: true;
$is-engraving-has-choice-8: true;

// LUX Overrides
$regional_shared_account_signin: true;
$regional_shared_account_forget_password: true;
$regional_shared_checkout_signin: true;
$regional_shared_account_profile: true;
